import { enGB } from 'date-fns/locale/en-GB';
import { es } from 'date-fns/locale/es';
import type { Locale } from 'date-fns';
import type { Localized } from '../locale';

export const LanguageCodeToLocaleMap: Localized<Locale> = {
  en: enGB,
  es: es,

  // Not used
  sv: enGB,
  fi: enGB,
  de: enGB,
  fr: enGB,
  it: enGB,
  et: enGB,
  ru: enGB,
  lv: enGB,
};
